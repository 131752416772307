
import '@/assets/css/iframeStyle.scss';
import { Component, Vue } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";

@Component({
    name: "SaleHome",
    components: {
        ContentTitle,
    },
})
export default class SaleHome extends Vue {
    private contentTitle: string = "销售首页";
    private baseUrlIframe = process.env.VUE_APP_URL;
    private loading: boolean = true;

    private created() {
        this.$nextTick( () => {
            // 注意： 如果本身iframe嵌套的盒子有延迟 则下面判断最好也加延迟
            const iframe: any = document.querySelector('#sale_Home_iframe');
            // 处理兼容行问题
            if (iframe.attachEvent) {
                iframe.attachEvent('onload', () => {
                    this.loading = false;
                });
            } else {
                iframe.onload = () => {
                    this.loading = false;
                };
            }
        });
    }
}
