
import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/header.vue';
import Menue from "@/components/Menue.vue";
import {saleMenuesData} from "@/utils/menue";
import { setLocalAndSession } from '@/utils/utils';

@Component({
  components: {
    Header,
    Menue,
  },
})
export default class Index extends Vue {
    // 菜单栏默认选中
    private activeIndex: string = this.$store.state.currentPath;
    private menuData: any = [];
    private headerFlag: boolean = false;
    private infoFlag: boolean = false;
    private centerType: string = 'sale';
    private isRouterAlive: boolean =  true;

    private reload() {
        this.isRouterAlive = false;
        this.$nextTick(function() {
            this.isRouterAlive = true;
        });
    }
    private handleSelect(index: any) {
        if ('/' + this.centerType + '/' + index === this.$route.path) {
            this.reload();
        }
    }

    // 获取初始菜单栏数据
    private created() {
        this.$nextTick(() => {
            this.initData();
            // 获取当前用户信息
            this.$httpService.getData({}, '/apiProxy/api/frontend/users/current').then((res: any) => {
                this.infoFlag = true;
                this.$store.commit('saveUserInfo', res);
                setLocalAndSession(res);
            });
        });
    }
    // 获取初始菜单栏数据
    private initData() {
        this.menuData = saleMenuesData;
        this.headerFlag = true;
        // 将菜单栏初始数据放到vuex
        this.$store.commit('saveMenuData', this.menuData );
        localStorage.setItem("menusData", JSON.stringify(this.menuData));
        // // 获取认证状态
        // this.$httpService.getData({}, '/api/renzheng').then((res: any) => {
        //     this.$store.commit('saveRenzheng', res.renzheng);
        // });
    }

    // 监听水平导航里的点击事件，与菜单栏选中一致
    @Watch('$store.state.currentPath')
    private onChanged(newValue: string, oldValue: string) {
      this.activeIndex = newValue;
    }

}
