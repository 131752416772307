
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'Menue',
})
export default class Menue extends Vue {
    @Prop(Array) private navMenus!: [];
    @Prop(String) private type!: "";
    private cert: number = 0; // 已认证
    private partnerStatus: number = 3; // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入")

    private created() {
        this.$nextTick(() => {
            this.cert = this.$store.state.userInfo.certStatus;
            this.partnerStatus = this.$store.state.userInfo.partnerStatus;
        });
    }
    private menuDisplayed(menuName: any) {
        // 认证成功并是生态伙伴才有报备客户菜单
        // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (3, "已终止") 报备客户的菜单，只有合作伙伴能看到
        if (this.cert === 2 && menuName === 'reportCustomer' && this.partnerStatus !== 3 && this.partnerStatus !== 4) {
            return true;
        // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (3, "已终止") 分润管理的菜单，只有合作伙伴能看到
        } else if (this.cert === 2 && menuName === 'profitManage'
            && this.partnerStatus !== 3 && this.partnerStatus !== 4) {
            return true;
        // 后台配置允许的才有驱动开发（合作伙伴也有驱动开发）
        } else if (menuName === 'driveDevelopment' && this.cert === 2
            && this.partnerStatus !== 3 && this.partnerStatus !== 4) {
            return true;
        } else if (menuName !== 'reportCustomer' && menuName !== 'driveDevelopment' && menuName !== 'profitManage') {
            return true;
        }
        return false;
    }
}
