
// 获取菜单栏数据，删除合同管理
const menuesData = [{
  // 一级
  entity: {
    id: 0,
    name: "companyHome",
    icon: "el-icon-monitor",
    alias: " 企业首页",
    value: "companyHome",
  },
},
{
  // 一级
  entity: {
    id: 1,
    name: "systemManage",
    icon: "el-icon-office-building",
    alias: "企业管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 2,
        name: "material",
        alias: "企业资料",
        value: "material",
      },
    },
    {
      entity: {
        id: 3,
        name: "customer",
        alias: "客服管理",
        value: "customer",
      },
    },
    {
      entity: {
        id: 4,
        name: "staff",
        alias: "员工管理",
        value: "staff",
      },
    },
    {
      entity: {
        id: 5,
        name: "organization",
        alias: "组织机构",
        value: "organization",
      },
    },
    {
      entity: {
        id: 6,
        name: "address",
        alias: "收货地址",
        value: "address",
      },
    },
    {
      entity: {
        id: 7,
        name: "security",
        alias: "安全设置",
        value: "security",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 8,
    name: "trailManage",
    icon: "el-icon-potato-strips",
    alias: "试用管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 9,
        name: "trail",
        alias: "我的试用",
        value: "trail",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 10,
    name: "experienceManage",
    icon: "el-icon-cloudy-and-sunny",
    alias: "体验管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 11,
        name: "experience",
        alias: "我的体验",
        value: "experience",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 12,
    name: "test4",
    icon: "el-icon-notebook-2",
    alias: "资源管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 13,
        name: "resource",
        alias: "云服务器",
        value: "resource",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 14,
    name: "productAuthorize",
    icon: "el-icon-bangzhu",
    alias: "产品授权",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 15,
        name: "reportCustomer",
        alias: "报备客户",
        value: "reportCustomer",
      },
    },
    {
      entity: {
        id: 16,
        name: "projectList",
        alias: "项目列表",
        value: "projectList",
      },
    },
    {
      entity: {
        id: 17,
        name: "authorize",
        alias: "授权历史",
        value: "authorize",
      },
    },
    {
      entity: {
        id: 28,
        name: "deviceDriveBuy",
        alias: "设备驱动购买",
        value: "deviceDriveBuy",
      },
    }],
},
{
  // 一级
  entity: {
    id: 18,
    name: "order",
    icon: "el-icon-document",
    alias: "订单管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 19,
        name: "myOrder",
        alias: "我的订单",
        value: "myOrder",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 20,
    name: "demand",
    icon: "el-icon-bell",
    alias: "需求管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 21,
        name: "myDemand",
        alias: "我的需求",
        value: "myDemand",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 22,
    name: "documentation",
    icon: "el-icon-wallet",
    alias: "开发中心",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 23,
        name: "driveDevelopment",
        alias: "驱动开发",
        value: "driveDevelopment",
      },
    },
    {
      entity: {
        id: 24,
        name: "docCenter",
        alias: "开发文档",
        value: "docCenter",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 25,
    name: "eco",
    icon: "el-icon-reading",
    alias: " 生态合作",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 26,
        name: "ecoPartner",
        alias: "合作管理",
        value: "ecoPartner",
      },
    },
    {
      entity: {
        id: 27,
        name: "profitManage",
        alias: "分润管理",
        value: "profitManage",
      },
    },
  ],
}];
export const personMenuesData = [{
  // 一级
  entity: {
    id: 0,
    name: "personHome",
    icon: "el-icon-monitor",
    alias: "个人首页",
    value: "personHome",
  },
},
{
  // 一级
  entity: {
    id: 1,
    name: "accountManage",
    icon: "el-icon-office-building",
    alias: "账户管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 2,
        name: "perMaterial",
        alias: "个人资料",
        value: "perMaterial",
      },
    },
    {
      entity: {
        id: 3,
        name: "binding",
        alias: "绑定管理",
        value: "binding",
      },
    },
    {
      entity: {
        id: 4,
        name: "perSecurity",
        alias: "安全设置",
        value: "perSecurity",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 5,
    name: "documentation",
    icon: "el-icon-wallet",
    alias: "开发中心",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 6,
        name: "docCenter",
        alias: "开发文档",
        value: "docCenter",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 7,
    name: "experienceManage",
    icon: "el-icon-cloudy-and-sunny",
    alias: "体验管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 8,
        name: "perExperience",
        alias: "我的体验",
        value: "perExperience",
      },
    },
  ],
}];
export const saleMenuesData = [
// {
//   // 一级
//   entity: {
//     id: 0,
//     name: "saleHome",
//     icon: "el-icon-monitor",
//     alias: "销售首页",
//     value: "saleHome",
//   },
// },
{
  // 一级
  entity: {
    id: 1,
    name: "appManage",
    icon: "el-icon-office-building",
    alias: "应用管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 2,
        name: "myApp",
        alias: "我的应用",
        value: "myApp",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 3,
    name: "saasManage",
    icon: "el-icon-notebook-2",
    alias: "SaaS管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 4,
        name: "saasProduct",
        alias: "SaaS产品",
        value: "saasProduct",
      },
    },
  ],
},
{
  // 一级
  entity: {
    id: 5,
    name: "orderManage",
    icon: "el-icon-bangzhu",
    alias: "订单管理",
  },
  // 二级
  childs: [
    {
      entity: {
        id: 6,
        name: "saleOrder",
        alias: "销售订单",
        value: "saleOrder",
      },
    },
  ],
},
// {
//   // 一级
//   entity: {
//     id: 7,
//     name: "financeManage",
//     icon: "el-icon-document",
//     alias: "财务管理",
//   },
//   // 二级
//   childs: [
//     {
//       entity: {
//         id: 8,
//         name: "finance",
//         alias: "财务首页",
//         value: "finance",
//       },
//     },
//     {
//       entity: {
//         id: 9,
//         name: "invoice",
//         alias: "开票列表",
//         value: "invoice",
//       },
//     },
//     {
//       entity: {
//         id: 10,
//         name: "withdraw",
//         alias: "提现列表",
//         value: "withdraw",
//       },
//     },
//   ],
// }
];
export default menuesData;
